<template>
	<div class="network">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageNetwork" :isBannerReady="isContentReady"></gadget-ads-banner>

			<div class="main-loading" v-if="!isContentReady">
				<div class="main">
					<loader-content :isDisplay="true"></loader-content>
				</div>
				<article class="mt-2">
					<loader-content :isDisplay="true"></loader-content>
				</article>
			</div>
			<template v-if="isContentReady">
				<network-detail :network="networkData"></network-detail>
			</template>

			<div class="content-container mt-3">
				<div class="filter">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<template v-if="isContentReady">
						<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
							<div class="box" @click.stop>
								<perfect-scrollbar>
									<div class="filter-title">
										<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
									</div>
									<filter-search
										v-if="filterOptions"
										ref="filterSearch"
										:filterOptions="filterOptions"
										:tagSelections="tags"
										@filterResult="changeFilter($event)">
									</filter-search>
								</perfect-scrollbar>
								<div class="operation-panel">
									<div class="wrapper">
										<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
										<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()" v-if="searchMode === constants.NETWORK_SEARCH_MODE.TIRE">ดูสินค้า {{ totalResult }} รายการ</button>
										<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()" v-if="searchMode === constants.NETWORK_SEARCH_MODE.STORE">ดูร้านค้า {{ totalResult }} ร้าน</button>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="content-body">
					<gadget-alert-geolocation
						class="mt-3 mb-2"
						:state="displayAlertGeolocation"
						v-if="displayAlertGeolocation > 0 && sortTypeStore === 'nearby' && searchMode === constants.NETWORK_SEARCH_MODE.STORE">
					</gadget-alert-geolocation>

					<network-box-searcher
						v-if="isContentReady"
						:initSearchMode="searchMode"
						:hasSeller="networkData.hasSeller"
						@searchMode="changeSearchMode($event)"
						@searchTire="searchTire($event)"
						@searchStoreKeyword="searchStoreByKeyword($event)"
						@searchStoreNearby="searchStoreNearBy()"
						class="mt-3">
					</network-box-searcher>

					<div class="list mt-3" v-if="isContentReady">
						<div class="content-mobile-filter mb-3">
							<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
						</div>
						<product-total-result class="mb-3" :totalResult="totalResult" v-if="searchMode === constants.NETWORK_SEARCH_MODE.TIRE"></product-total-result>

						<gadget-sort-bar
							:sortOption="sortProductOption"
							:defaultOption="sortProductOption[0].id"
							class="mt-3 mb-3"
							@changeSort="changeSortProduct($event)"
							v-show="searchMode === constants.NETWORK_SEARCH_MODE.TIRE && isResultReady">
						</gadget-sort-bar>
						<gadget-sort-bar
							:sortOption="sortStoreOption"
							:defaultOption="sortStoreOption[0].id"
							class="mt-3 mb-3"
							@changeSort="changeSortStore($event)"
							v-show="searchMode === constants.NETWORK_SEARCH_MODE.STORE && isResultReady">
						</gadget-sort-bar>

						<div class="loading" v-if="!isResultReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>

						<template v-if="isResultReady">
							<product-grid
								:products="productList"
								:networkId="networkId"
								:type="constants.ITEM_BOX_TYPE.NETWORK"
								v-if="searchMode === constants.NETWORK_SEARCH_MODE.TIRE"
								@openStoreList="openStoreList($event)"
								trackingReferrer="network"
								:initStoreFilter="filterSet.store">
							</product-grid>
							<store-grid
								:stores="storeList"
								v-if="searchMode === constants.NETWORK_SEARCH_MODE.STORE">
							</store-grid>
							<paging
								@page="changePage($event)"
								:activePage="activePage"
								:totalPage="totalPage"
								class="mt-4 mb-2">
							</paging>
						</template>
					</div>

					<article id="articleContent" class="content-article mt-4 mb-4" v-html="networkData.content" v-if="networkData.content"></article>

					<!-- <div class="content-related-items mt-5">
						<div class="loading" v-if="!isContentRelateReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>

						<template v-if="isContentRelateReady">
							<blog-relate
								:relatePromotions="relatePromotions"
								:relateArticlesLatest="relateArticlesLatest"
								:relateArticlesByCategory="relateArticlesByCategory">
							</blog-relate>
						</template>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import OptionProductSort from '@/services/staticOption/productSort';
import NetworkService from '@/services/networkService';
import MixinGeolocationContent from '@/mixins/geolocationContent';
import FilterSearch from '@/components/gadget/FilterSearch';
import NetworkDetail from '@/components/network/NetworkDetail';
import NetworkBoxSearcher from '@/components/network/BoxSearcher';
import ProductGrid from '@/components/product/ProductGridList';
import ProductTotalResult from '@/components/product/TotalResult';
import StoreGrid from '@/components/store/StoreGridList';
import GadgetAlertGeolocation from '@/components/gadget/AlertGeolocation';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import GadgetSortBar from '@/components/gadget/SortBar';
// import BlogRelate from '@/components/blog/BlogRelate';

export default {
	mixins: [ MixinGeolocationContent ],
	components: {
		GadgetAdsBanner,
		FilterSearch,
		NetworkDetail,
		NetworkBoxSearcher,
		ProductGrid,
		ProductTotalResult,
		StoreGrid,
		GadgetAlertGeolocation,
		GadgetSortBar,
		// BlogRelate
	},
	data() {
		return {
			constants: Constants,
			isContentReady: false,
			isResultReady: false,
			isShowMobileFilter: false,
			topBanner: null,
			tags: [],
			filterSet: { product: null, store: null },
			filterOptions: null,
			networkData: null,
			networkId: Helper.splitDotParam(this.$route.params.key),
			productList: null,
			totalResult: 0,
			storeList: null,
			activePage: 1,
			totalPage: 0,
			filter: null,
			sortProductOption: OptionProductSort.option,
			sortStoreOption: [
				{ id: 'nearby', name: 'ร้านที่ใกล้' }
			],
			sortTypeProduct: 'popular',
			sortTypeStore: 'nearby',
			searchMode: -1,
			tireCriteria: {
				width: '',
				ratio: '',
				diameter: ''
			},
			storeCriteria: {
				keyword: ''
			},
			isContentRelateReady: false,
			relatePromotions: [],
			relateArticlesLatest: [],
			relateArticlesByCategory: [],
		};
	},
	mounted() {
        this.getContent();
		// this.getRelatedContent();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamNetwork();
            const result = await NetworkService.getNetworkDetail(param);

			if (result.data) {
				this.topBanner = result.data.ads;
				this.filterSet = result.data.filter;
				this.networkData = result.data.networkData;
				this.searchMode = this.networkData.hasSeller ? Constants.NETWORK_SEARCH_MODE.TIRE : Constants.NETWORK_SEARCH_MODE.STORE;
			}

			this.isContentReady = true;
        },
		getSearchResult() {
			switch (this.searchMode) {
				case Constants.NETWORK_SEARCH_MODE.TIRE:
					this.filterOptions = this.filterSet.product;
					this.getProductResult();
					break;

				case Constants.NETWORK_SEARCH_MODE.STORE:
					this.filterOptions = this.filterSet.store;
					this.getStoreResult();
					break;
			}
		},
		async getProductResult() {
			// Force to change back mode
			this.searchMode = Constants.NETWORK_SEARCH_MODE.TIRE;

			this.isResultReady = false;

			const param = this.setupParamProduct();
            const result = await NetworkService.getProductList(param);

			this.productList = result.data.resultList;
			this.totalResult = result.data.totalResult;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
		},
		async getStoreResult() {
			// Force to change back mode
			this.searchMode = Constants.NETWORK_SEARCH_MODE.STORE;

			this.isResultReady = false;

			const param = await this.setupParamStore();
            const result = await NetworkService.getStoreList(param);

			this.storeList = result.data.resultList;
			this.totalResult = result.data.totalResult;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
		},
		async changeSearchMode(value) {
			if (value === Constants.NETWORK_SEARCH_MODE.STORE) {
				await this.checkGeolocation();
			}

			this.activePage = 1;
			this.searchMode = value;
			this.getSearchResult();
		},
		setupParamNetwork() {
			const param = {
				key: Helper.splitDotParam(this.$route.params.key),
				urlReferrer: document.referrer
			};

			return param;
		},
		setupParamProduct() {
			const param = {
				page: this.activePage,
				network: Helper.splitDotParam(this.$route.params.key),
				width: this.tireCriteria.width,
				ratio: this.tireCriteria.ratio,
				diameter: this.tireCriteria.diameter,
				filter: this.filter,
				sort: this.sortTypeProduct
			};

			return param;
		},
		async setupParamStore() {
			const position = this.geolocationState === 'granted' ? await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}) : null;

			const param = {
				page: this.activePage,
				network: Helper.splitDotParam(this.$route.params.key),
				keyword: this.storeCriteria.keyword,
				latitude: position ? position.coords.latitude : null,
				longtitude: position ? position.coords.longitude : null,
				filter: this.filter,
				sort: this.sortTypeStore
			};

			return param;
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changePage(page) {
			this.activePage = page;
			this.getSearchResult();
		},
		changeSortProduct(data) {
			this.sortTypeProduct = data.value;

			if (this.sortTypeProduct) {
				this.changePage(1);
			}
		},
		changeSortStore(data) {
			this.sortTypeStore = data.value;

			if (this.sortTypeStore) {
				this.changePage(1);
			}
		},
		searchTire(data) {
			this.tireCriteria = {
				width: data.width,
				ratio: data.ratio,
				diameter: data.diameter
			};

			this.changePage(1);
		},
		searchStoreByKeyword(data) {
			this.storeCriteria.keyword = data.keyword;
			this.changePage(1);
		},
		searchStoreNearBy() {
			this.changePage(1);
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
			this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		},
		async getRelatedContent() {
            this.isContentRelateReady = false;

            const result = await NetworkService.getRelatedContent(Helper.splitDotParam(this.$route.params.key));

			if (result?.data) {
				this.relatePromotions = result.data.promotions;
				this.relateArticlesLatest = result.data.articlesLatest;
				this.relateArticlesByCategory = result.data.articlesByCategory;
			}

            this.isContentRelateReady = true;
        }
    },
	metaInfo() {
		const title = this.networkData ? this.networkData.name + ' | YELLOWTiRE' : Helper.metaDefault().title;
		const desc = this.networkData ? this.networkData.slogan : Helper.metaDefault().desc;
		const image = this.networkData ? this.networkData.logo : location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.network {
	padding: 15px 0 25px;

	.main-loading {
		.main {
			height: 150px;
		}

		article {
			height: 150px;
		}
	}

	.list {
		position: relative;

		.loading {
			.item {
				height: 250px;
			}
		}
	}
}
</style>