<template>
	<div class="network-detail">
        <section class="detail-box">
            <div class="main">
                <div class="pictures" v-if="network.pictures.length > 1">
                    <swiper :options="swiperOptions" class="sliders">
                        <swiper-slide v-for="(item, index) in network.pictures" :key="index">
                            <template v-if="item.link">
                                <a :href="item.link" target="_blank" :title="network.name">
                                    <img :src="item.picture"
                                        :alt="item.alt"
                                        :title="item.alt" />
                                </a>
                            </template>
                            <template v-else>
                                <img :src="item.picture"
                                    :alt="item.alt"
                                    :title="item.alt" />
                            </template>                        
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
                <div class="pictures" v-if="network.pictures.length === 1">
                    <div class="sliders">
                        <template v-if="network.pictures[0].link">
                            <a :href="network.pictures[0].link" target="_blank" :title="network.name">
                                <img :src="network.pictures[0].picture"
                                    :alt="network.pictures[0].alt"
                                    :title="network.pictures[0].alt" />
                            </a>
                        </template>
                        <template v-else>
                            <img :src="network.pictures[0].picture"
                                :alt="network.pictures[0].alt"
                                :title="network.pictures[0].alt" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="info">
                <div class="picture">
                    <img :src="network.logo"
                        :alt="network.name"
                        :title="network.name" />
                </div>
                <div class="title">
                    <h1>{{ network.name }}</h1>
                    <p>{{ network.slogan }}</p>
                    <article class="d-none d-md-block" v-html="network.description" v-if="network.description"></article>
                </div>
            </div>
        </section>

		<article class="mt-2 d-md-none" v-html="network.description" v-if="network.description"></article>
	</div>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';

export default {
    mixins: [ MixinDragScroll ],
	props: {
        network: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.network-detail {
    .detail-box {
        @include boxShadow(0 0 5px rgba(0, 0, 0, 0.1));
    }
    
    .main {
        .pictures {
            position: relative;

            .sliders {
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .info {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 10px;

        .picture {
            width: 114px;
            margin-right: 20px;

            @media only screen and (max-width: $screenSmall) {
                width: 80px;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                width: 70px;
                margin-right: 10px;
            }
            
            img {
                display: block;
                border: 1px solid #333;
                width: 100%;
            }
        }

        .title {
            flex: 1;

            h1 {
                font-weight: bold;
                font-size: 28px;
                line-height: 30px;
                color: #555;
                margin-bottom: 5px;

                @media only screen and (max-width: $screenSmall) {
                    font-size: 24px;
                    line-height: 26px;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 18px;
                color: #888;
            }
        }
    }

    article {
        font-size: 14px;
        line-height: 18px;
        color: #888;

        @media only screen and (max-width: $screenSmall) {
            font-size: 13px;
            line-height: 18px;
            color: #333;
        }
    }
}
</style>